<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Link Terkait</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Jurnal Polibatam</a>
            </li>
            <li>
              <a routerLink="./" class="">RAMA Kemendikbud</a>
            </li>
            <li>
              <a routerLink="./" class="">Indonesian Onesearch</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
       <!--   <h5 class="text-uppercase">Contact us</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Email : repository@polibatam.ac.id</a>
            </li>
            <li>
              <a routerLink="./" class="">Powered by UPA Perpustaakaan Polibatam</a>
            </li>
          </ul>-->
        </div> 
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Butuh Bantuan?</h5>

          <p>
            Silahkan hubungi kami melalui email <a href="mailto:perpus@polibatam.ac.id">disini</a> atau lihat panduan melalui link <a href="https://lib.polibatam.ac.id/panduan/unggah-mandiri/">disini</a>.<br>
Nomor Kontak (Ongoing).
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">Polibatam 
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://repository.polibatam.ac.id/">Repository Politeknik Negeri Batam</a>
      </p>
      <!--<ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
            <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li *ngIf="showPrivacyPolicy">
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li *ngIf="showEndUserAgreement">
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>-->
    </div>
  </div>
  <!-- Copyright -->
</footer>
